/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import 'ngx-toastr/toastr';

body {
  width: 100vw;
  height: 100vh;
  background-color:  #f4f6f9
}

::-webkit-scrollbar {
  width: 0px;
  background: black;
}

.content {
  padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}


.custom-loader {
  width: 100px;
  height: 100px;
  display: grid;
}
.custom-loader::before,
.custom-loader::after {
  content:"";
  grid-area: 1/1;
  --c: radial-gradient(farthest-side,#b4e3e9 92%,#0000);
  background:
    var(--c) 50%  0,
    var(--c) 50%  100%,
    var(--c) 100% 50%,
    var(--c) 0    50%;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  animation: s2 1s infinite;
}
.custom-loader::before {
margin:8px;
filter:hue-rotate(45deg);
background-size: 16px 16px;
animation-timing-function: linear
}

@keyframes s2{
100%{transform: rotate(.5turn)}
}
